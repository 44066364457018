import { createApp } from "/node_modules/.vite/deps/vue.js?v=1f526353"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=451309ad"
import App from "/src/App.vue"
import router from "/src/router/index.js"
import Toast from "/node_modules/.vite/deps/vue-toastification.js?v=d5c92780"
import "/node_modules/vue-toastification/dist/index.css"
import "/src/services/api.js"

// Import Tailwind CSS
import "/src/assets/tailwind.css"

// Create Vue app and Pinia store
const app = createApp(App)
const pinia = createPinia()

// Configure toast options
const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
}

// Use plugins
app.use(pinia)
app.use(router)
app.use(Toast, toastOptions)

// Import and initialize auth store after Pinia is installed
import { useAuthStore } from "/src/stores/auth.js"
const authStore = useAuthStore()
await authStore.initializeAuth()

// Mount app
app.mount('#app')
